.c-spaces-front-modals {

  $this: &;

  @at-root {


    #{$this}__footer-forminputs {
      width: 100%;
      height:30px;

      & .btn-validate { float:right }
    }



    #{$this}__footer-authenticated-code {
      height:30px;
      width:100%;

      & .btn-validate {
        float:right;
        margin-left:15px;
      }

      & .btn-resend-code {
        float:left;
        color: var(--theme-color);
        text-decoration: none;
        font-weight:bold;
        font-size:14px;
      }
    }



    #{$this}__footer-authenticated-mail,
    #{$this}__footer-authenticated-askaccess {
      height:30px;
      width:100%;

      & .btn-validate { float:right; }
    }


  }
}