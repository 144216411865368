.c-tasks-members-popup {
  $this: &;

  @at-root {

    #{$this}__container {
      height: auto;
      max-height: 742px;
      width: 304px;
      top: 150px !important;
      right: 18px !important;
    }

    #{$this}__container-card-overlay {
      height: auto;
      max-height: 742px;
      width: 304px;
      top: 0 !important;
      right: 18px !important;
    }

    #{$this}__text-bottom {
      text-align: center;
    }

    #{$this}__item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    #{$this}__name-organisation {
      display: inline;
    }

    #{$this}__name-poste {
      display: inline !important;
      margin: 0;
      padding: 0;
    }

    #{$this}__search-mobile {
      margin-top: 9px !important;
    }

    #{$this}__results {
      height: calc(100vh - 160px);
      overflow-y: auto;
    }

  }
}