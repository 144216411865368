.c-tasks-header {

  $this: &;
  $offset: $size-x-small;
  $trigger-reveal-animation-duration: $speed-fast;

  position: fixed;
  z-index: $z-index-8;

  &__title {
    margin: 0;
    margin-left: $size-xxx-small;
    font-weight: 500;
    font-size: ($size-x-small)*2;
  }

  &__title-group{
    display: flex;
    flex-direction: column;
    margin-left: 9px;
    width: 90%;
  }

  &__icon {
    width: $size-small;
    height: $size-small;
  }

  &__researchContainerMaster{
    display: flex;
  }

  &__researchContainer{
    position: fixed;
    max-width: 100%;
    width: 200px;
    right: 95px;
    display: inline-flex;
    transition: 0.3s ease-in-out;
    top: 6px;
    z-index: 100;
    @media (max-width: $screen-sm-max) {
      top: unset;
      right: unset;
      width: 100%;
      position: unset;
    }
  }

  &__researchContainer--open{
    width: 40vw;
    transition: 0.3s ease-in-out;
    display: flex;
    @media (max-width: $screen-sm-max) {
      width: 90vw;
      margin-right: 20px;
      margin-left: 20px;
    }
  }

  &__researchIcon{
    width: 12px;
    height: 12px;
    position: absolute;
    top: 20px;
    left: 8px;
    display: inline-block;
    flex-shrink: 0;
    line-height: 1;
    @media (max-width: $screen-sm-max) {
      top: 70px;
      left: 30px;
    }
  }

  &__researchInput{
    height: 32px;
    width: 100%;
    padding: 0px 12px 0px 30px;
    box-sizing: border-box;
    outline: none;
    border-radius: 5px;
    font-size: 15px;
    line-height: 20px;
    border: 1px solid lightgrey;
  }

  &__researchPopup{
    border: 1px solid white;
    width: 40vw;
    border-radius: 3px;
    z-index: 9999;
    background-color: white;
    box-shadow:  0 4px 8px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31);
    padding: 15px;
    overflow: auto;
    flex: 1;
    display: none;
    flex-direction: column;
    min-height: 0;
    top: 52px;
    max-height: calc(115vh - 224px);
    right: 95px;
    position: fixed;
  }

  &__researchPopup--content{
    flex: 1;
    overflow-x: hidden;
    padding: 15px;
  }

  &__researchPopup--content{
    height: fit-content;
  }

  &__researchPopup--open{
    display: block;
  }

  &__iconResultResearchTasksAndLists{
    width: 32px;
    height: 27px;
    position: relative;
    top: 1px;
  }

  &__displayResultResearchTasksAndLists{
    color: #434a54;
    font-size: 13px;
    font-weight: 700;
    display: inline-flex;
    width: 100%;
  }

  &__researchNameResult{
    margin-bottom: 0;
    margin-top: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 15px;
    width: 100%;
    margin-left: 0;
  }

  &__researchLink{
    &:hover{
      display: block;
      background-color: #F4F5F7;
      text-decoration: none;
      cursor: pointer;
      color: #656565;
    }
  }

  &__researchLink-result{
    padding: 3px;
  }


  &__reaserchPopup--title{
    color: #5e6c84;
    font-size: 17px;
    font-weight: 600;
    line-height: 16px;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  &__reaserchPopup--title-mobile{
    color: #5e6c84;
    font-size: 17px;
    font-weight: 600;
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 8px;
  }

  &__buttonAddTask{

    @media (max-width: $screen-sm-max) {
      display: none;
    }

    position: fixed;
    right: 22px;
    top: 5px;
    padding: 0;
    border: none;
    background: none;
    font-size: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    transition: background-color ease 150ms, -webkit-transform ease 300ms;
    transition: background-color ease 150ms, transform ease 300ms;
    transition: background-color ease 150ms, transform ease 300ms, -webkit-transform ease 300ms;
    pointer-events: all;
    border-radius: 50%;
    background: var(--theme-color);
    //box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2), 0 6px 10px rgba(0, 0, 0, 0.14), 0 1px 18px rgba(0, 0, 0, 0.12);
    color: white;
  }

  &__buttonAddTask-mobile{
    @media (max-width: $screen-sm-max) {
      top: 692px;
      right: 14px;
      z-index: 10;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      top: 957px;
    }

    position: fixed;
    right: 22px;
    top: 5px;
    padding: 0;
    border: none;
    background: none;
    font-size: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    transition: background-color ease 150ms, -webkit-transform ease 300ms;
    transition: background-color ease 150ms, transform ease 300ms;
    transition: background-color ease 150ms, transform ease 300ms, -webkit-transform ease 300ms;
    pointer-events: all;
    border-radius: 50%;
    background: var(--theme-color);
    //box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2), 0 6px 10px rgba(0, 0, 0, 0.14), 0 1px 18px rgba(0, 0, 0, 0.12);
    color: white;

  }



  #{$this}__container
  {
    position: fixed;
    right: $offset;
    bottom: $offset;

    display: flex;
    align-items: center;
    flex-direction: column-reverse;

    pointer-events: none;

    // The floating action button must not be displayed above the online
    // users aside of the app (displayed at $screen-xl-min)
    @media #{$media-online-users-aside}
    {
      .has-main-asides &
      {
        right: $main-aside-width + $offset;
      }
    }

    #{$this}--items-down &
    {
      flex-direction: column;
    }

    .has-enabled-mobile-navbar &
    {
      bottom: $offset + $mobile-menu-height;
      bottom: calc(#{$offset} + var(--mobile-menu-height));

      @media #{$media-desktop}
      {
        bottom: $offset;
      }
    }

    #{$this}.is-expanded &
    {
      pointer-events: all;
    }
  }

}
