.c-tasks-popupMoveTask {
  $this: &;

  @at-root {
    #{$this}__container {
      width: 304px;
      top: 392px;
      right: 17px;
      max-height: 650px;
    }

    #{$this}__dropdown {
      margin-top: 10px;
    }

    #{$this}__button-move {
      margin-top: 10px;
    }

    #{$this}__button-move-mobile {
      margin-top: 3px;
    }

    #{$this}__ContainerTasklist {
      display: inline-flex;
      width: 95%;
    }

    #{$this}__TasklistName {
      margin-right: 10px;
      margin-left: 10px;
      color: rgb(67, 74, 84);
      font-size: 17px;
      display: inline-block;
      vertical-align: text-top;
      width: 85%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width: $screen-sm-min) {
        width: 70%;
      }
    }

    #{$this}__FavoriteTasklistStar {
      color: #efd163;
      font-weight: 800;
      position: relative;
      top: 6px;
      left: 13px;
      right: 13px;
      bottom: 6px;
      visibility: hidden;
    }

    #{$this}__TasklistBackground{
      background: linear-gradient(to top left, #8bc6ec, #374191);
      width: 40px;
      height: 25px;
      display: inline-block;
    }

    #{$this}__ButtonMove{
      width: 100px;
      margin: 10px;
      font-size: 16px;
      font-weight: 500;
    }

  }

}