.c-tasks-activity {
  $this: &;
  margin-bottom: $size-x-small;

  @at-root
  {
    #{$this}--header-only {
      margin-top: $size-xxx-small;
    }

    #{$this}__header {
      display: flex;
      align-items: end;
    }

    #{$this}__title-group {
      display: flex;
      align-items: baseline;
      @include single-line;

      &--header-only {
        flex-direction: column;

        & .c-tasks-activity__subtitle {
          margin-left: 0;
        }
      }
    }

    #{$this}__title,
    #{$this}__subtitle {
      margin: 0;
    }

    #{$this}__title {
      font-size: 15px;
      color: $text-color;
      margin-bottom: $size-xxx-small;
      font-weight: normal;
      margin-left: 9px;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      & strong {
        font-weight: 500;
      }
    }

    #{$this}__title--comment {
      margin-bottom: 0;
      margin-left: 0;
    }

    #{$this}__subtitle {
      font-size: 13px;
      color: $color-default;
      font-weight: normal;
      margin-left: $size-xxx-small;
    }

    #{$this}__body {
      margin-top: 8px
    }

    #{$this}__comment {
      border-radius: 4px;
      border: 2px solid $color-light;
      padding: $size-xx-small;
      margin-bottom: $size-xxx-small;
      &--editing {
        border: 2px solid  var(--theme-color);
      }
    }

    #{$this}__comment-item {
      padding: 0;
    }

    #{$this}__comment-txt {
      font-size: 15px;
      color: $text-color;
      word-break: break-word;
      white-space: break-spaces;
      width: auto;
      word-wrap: break-word;
    }

    #{$this}__highlight {
      font-weight: 500;
    }

    #{$this}__action {
      margin-right: $size-xxx-small;
    }

    #{$this}__link {
      color: #337ab7;
    }

    #{$this}__action,
    #{$this}__underline {
      text-decoration: underline;
    }
  }
}
