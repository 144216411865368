.c-tasks-popupCreateTask {
  $this: &;

  @at-root {

    #{$this}__container {
      padding: 15px;
    }

    #{$this}__header {
      border: 0;
      padding: 0px 0px 20px 0px;
    }

    #{$this}__body {
      padding: 0;
    }

    #{$this}__ia-parent {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    #{$this}__description {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    #{$this}__nav {
      justify-content: center;
    }

    #{$this}__navTabs.active > a {
      backdrop-filter: blur(15px);
    }

    #{$this}__navTabs {
      margin: auto;
    }

    #{$this}__dropdown {
      margin-top: 15px
    }

    #{$this}__ContainerButtonValidate {
      text-align: right;
    }

    #{$this}__ButtonValidate {
      top: 4px;
      font-size: 15px;
    }

    #{$this}__textareaTasklistName {
      font-size: 21px;
      max-height: 160px;
      min-height: 36px;
      overflow: hidden;
      overflow-wrap: break-word;
      overflow-y: auto;
      padding: 8px 12px;
      resize: none;
      background-color: #f5f7fa;
      height: 50px;
    }

    #{$this}__textareaTasklistDescription {
      font-size: 17px;
      max-height: 160px;
      min-height: 36px;
      overflow: hidden;
      overflow-wrap: break-word;
      overflow-y: auto;
      padding: 8px 12px;
      resize: none;
      height: 120px;
    }

    #{$this}__TasklistName {
      margin-right: 10px;
      margin-left: 10px;
      color: rgb(67, 74, 84);
      font-size: 17px;
      display: inline-block;
      vertical-align: text-top;
      width: 85%;
      @media (max-width: $screen-sm-min) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 70%;
      }
    }

    #{$this}__TasklistUserName {
      margin-right: 10px;
      margin-left: 10px;
      color: rgb(67, 74, 84);
      font-size: 17px;
      display: inline-block;
      vertical-align: text-top;
    }

    #{$this}__TasklistColumnDefault {
      font-weight: bold;
    }

    #{$this}__FavoriteTasklistStar {
      color: #efd163;
      font-weight: 800;
      position: relative;
      top: 6px;
      left: 15px;
      right: 15px;
      bottom: 6px;
      visibility: hidden;
    }

    #{$this}__NbrUsers{
      font-size: 15px;
      font-style: italic;
    }

    #{$this}__TasklistBackground{
      background: linear-gradient(to top left, #8bc6ec, #374191);
      width: 40px;
      height: 25px;
      display: inline-block;
    }

    #{$this}__UserName {
      margin-right: 10px;
      margin-left: 10px;
      color: rgb(67, 74, 84);
      font-size: 17px;
    }

    #{$this}__TagUserName {
      margin-left: 8px;
      color: rgb(67, 74, 84);
      font-size: 15px;
      vertical-align: sub;
      top: 2px;
      position: relative;
    }

    #{$this}--dropdown .k-input, #{$this}--dropdown .k-picker   {
      border: none !important;
    }

    #{$this}--dropdown .k-list-item.k-selected:hover, .k-selected.k-list-optionlabel:hover, .k-list-item.k-selected.k-hover, .k-selected.k-hover.k-list-optionlabel   {
      background-color: #ebebeb !important;
      color: #444;
    }

    #{$this}--dropdown .c-tasks-grid__grid .k-grid td.k-selected, .k-grid tr.k-selected > td{
      background-color: white !important;
      border-bottom: 1px solid lightgray !important;
    }

    #{$this}--dropdown .k-picker-solid.k-focus    {
      box-shadow: none !important;
    }

    #{$this}--dropdown .k-picker-solid  {
     background-color: #f5f7fa !important;
    }

    #{$this}--dropdown .k-input-button:hover  {
     background-color: #f5f7fa !important;
    }

    #{$this}--dropdown .k-picker .k-input-button  {
     background-color: #f5f7fa !important;
    }

    #{$this}--dropdown .k-list-ul {
      margin-left: 10px !important;
      margin-right: 10px !important;
    }

    #{$this}--dropdown .k-input-inner {
     height: 40px !important;
      font-size: 17px !important;
    }

    #{$this}--dropdown .c-btn--mint:not(:disabled):hover {
      background-color: #81ddc6 !important;
    }

    #{$this}__changePositionContextForTaskCreatePopup{
      top: 18.75em !important;
      left: 4em !important;
    }
    #{$this}__changeStylizeTextForTaskCreatePopup{
      top: 12.5% !important;
      left: 100% !important;
    }

    #{$this}__changeOptimizedTextForTaskCreatePopup {
      top: 37.5% !important;
      left: 100% !important;
    }

    #{$this}__changeTonalityForTaskCreatePopup {
      top: 50% !important;
      left: 100% !important;
    }

    #{$this}__changeLanguageForTaskCreatePopup {
      top: 62.5% !important;
      left: 100% !important;
    }
    #{$this}__action--ia{
        border: none;
        background-color: transparent;
        text-align: center;
        flex: 1;
    }
    #{$this}__ia-icon{
      width:20px;
    }
  }

}