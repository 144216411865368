.c-tasks-assignation {
  $this: &;

  @at-root {

    #{$this}__container {
      height: auto;
      width: 378px;
      top: 195px !important;
      right: 300px !important;
    }

    #{$this}__container--mobile {
      height: auto;
      overflow-y: unset;
    }

    #{$this}__results--mobile {
      height: calc(100vh - 160px);
      overflow-y: auto;
    }

    #{$this}__info--mobile {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    #{$this}__info {
      width: 100%;
        &--subtitle{
          display: inline;
        }
    }

    #{$this}__message {
      display: inline-flex;
    }

    #{$this}__subtitle {
      display: inline !important;
      margin: 0;
      padding: 0
    }

    #{$this}__messageText {
      font-size: 14px;
    }

  }
}