.c-dashboard-info {
  $this:&;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @at-root
  {
    #{$this}__container {
      display: flex;
    }

    #{$this}__date {
      padding-top: 2px;
    }

    @mixin c-dashboard-info--color($bg-color, $bg-color-light) {
      #{$this}__logo {
        color: $bg-color-light!important;
      }

      #{$this}__title {
        color: $bg-color!important;
      }
    }

    #{$this}--blue {
      @include c-dashboard-info--color($dashboard-color-blue, $dashboard-color-blue-light);
    }

    #{$this}--red {
      @include c-dashboard-info--color($dashboard-color-red, $dashboard-color-red-light);
    }

    #{$this}--orange {
      @include c-dashboard-info--color($dashboard-color-orange, $dashboard-color-orange-light);
    }

    #{$this}--green {
      @include c-dashboard-info--color($dashboard-color-green, $dashboard-color-green-light);
    }

    #{$this}--purple {
      @include c-dashboard-info--color($dashboard-color-purple, $dashboard-color-purple-light);
    }

    #{$this}__logo {
      width: $size-small * 2;
      height: $size-small * 2;
      margin: 0 0 $size-xx-small 0;
      color: var(--theme-color-light);
    }

    #{$this}__title {
      font-weight: 500;
      text-align: center;
      font-size: $size-small * 2;
      margin: 0 0 $size-xx-small 0;
      color: var(--theme-color);
    }

    #{$this}__subtitle {
      margin: 0;
      font-weight: 500;
      font-size: $size-xx-small * 2;
      color: $color-dark;
    }
  }
}
