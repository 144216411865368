.c-tasks-details-popup {
  &__container {
    display: flex;
    flex-direction: column;
    max-height: 700px;
    height: 100%;
  }

  &__header {
    width: 100%;
    padding: $size-xx-small;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    flex-grow: 0;
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 15px;
    margin: 0;
    color: $text-color;
  }

  &__btn {
    background: transparent;
    border: 0;
    color: var(--theme-color);
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
    text-align: end;
  }

  &__buttonDate--dateSubmit{
    background-color: #026aa7;
    box-shadow: none;
    border: none;
    color: #ffffff;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 8px;
    height: 30px;
    border-radius: 3px;
  }

  &__button-container-date--Mobile{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  &__buttons-date--Mobile{
    flex: 1;
    margin: 20px;
  }

  &__buttonDate--dateCancel{
    background-color: rgba(9, 30, 66, 0.04);
    box-shadow: none;
    border: none;
    color: #172b4d;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    height: 30px;
    border-radius: 3px;
  }

  &__search {
    margin: 0 $size-xx-small $size-xx-small $size-xx-small;
    flex-shrink: 0;
    flex-grow: 0;
  }

  &__results {
    padding: $size-xx-small;
    flex-grow: 1;
    //overscroll-behavior: contain;
  }

  &__no-results {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__no-results-img {
    margin-bottom: $size-xx-small;
  }

  &__search-input {
    padding-left: $size-xx-small!important;
  }

  &__content {
    margin: $size-xxx-small;

    &--no-margin {
      margin: 0;
    }
  }

  &__infos {
    display: flex;
    align-items: center;
    padding: $size-xx-small;
    justify-content: space-between;

    & p {
      margin: 0;
      padding: 0;
    }
  }

  &__list-item {
    padding: 10px;
    font-size: 15px;
    cursor: pointer;

    & a,
    & a:hover {
      text-decoration: none;
      color: $text-color;
      border-bottom: none;
    }

    &:hover {
      background-color: var(--theme-color-l95);
    }
  }

  @media(min-width: $screen-md-min) {
    &__buttonAssignation:hover .u-more-trigger{
      visibility: visible;
    }
  }
  &__buttonAssignation:last-child {
    padding-bottom: 70px;
  }

  .k-dropdown-wrap, .k-picker-wrap {
      border: solid 1px #e6e6e6;
      border-radius: 2px;

  }
  input:disabled {
    border: none;
  }
  .k-select {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: solid 1px #e6e6e6;
    background: #FAFAFA;
    width: 2em;
    height: auto;
    padding: 0 1em;
  }
  .k-icon {
    vertical-align: middle;
  }
  .k-input {
    width: initial;
  }
  .k-input-button {
    margin: 0;
  }
  .c-tasks-details-section__action-icon {
    margin: 0;
  }
  .k-state-disabled {
    .k-icon {
      opacity: 0.5;
    }
  }
  .c-tasks-details-section__action-icon {
    margin: 0;
  }
  .k-state-disabled {
    .k-icon {
      opacity: 0.5;
    }
  }
}