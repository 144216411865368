.c-onlyoffice-viewer {

  $this: &;
  border: none;


  &.header {
    display: flex;
    height: 40px;
    width: 100%;

    padding-left:10px;

    align-items: center;
  }


  &.content {
    width: 100%;
    height: calc(100% - 40px);

    &--notemplate{
      height: 100% !important;
    }
  }



  @at-root {

  }

}