.c-tasks-modal__body--sidebar {
  overflow-y:auto;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  @include custom-scrollbar();

  .c-tasks-details {
    flex-direction: column;
    .c-params-title--sidebar {
      margin-top:30px;
    }
  }

  .c-params-title--sidebar {
    font-size: 20px;
    margin: $size-xxx-small;
  }

  .c-params-title--menu {
    width: 33.3%
  }

  .c-params-title--menu {
    width: 33.3%
  }

  @media screen and (max-width: 768px) {
    .c-tasks-details {
      .c-params-title--sidebar {
        margin-top: 20px;
      }
    }
    .c-params-title--sidebar {
      font-size: 23px;
    }
  }

  label {
    font-size: clamp(15px,1vw,18px);
    display: flex;
    margin: 0;
    padding: 0;
  }

  .c-params-title:not(:first-child) {
    margin-bottom: 7px;
  }

  @media screen and (max-width: 768px) {
    .c-params-title:not(:first-child) {
      margin-top: 5px;
    }
  }

  .c-params-title {
    margin: $size-xxx-small 0 $size-xxx-small $size-xxx-small;
  }

  .c-wrapper-toggle-btn--sidebar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:5px;

    background: #D9D9D9;
    padding: 5px;
    border-radius:15px;

    width: 100%;

    .c-radio--sidebar {
      display: flex;
      align-items: center;
      justify-content: center;

      background: white;
      color: #868686;
      border-radius: 10px;
      padding: 13px;
      width: 50%;
      transition: all 0.2s ease-in-out;
      height: 15px;
      border: 2px solid transparent;
    }

    .c-radio--sidebar:hover {
      transition: all 0.2s ease-in-out;
      color: black;
      cursor: pointer;
    }

    .c-radio--sidebar.is-checked {
      background: rgba(55, 65, 145, 1) !important;
      color: white;
      border: solid 2px white;
    }


    .c-radio__input--sidebar {
      display: none;
    }
    .c-radio__label--sidebar {
      font-size: 17px;
      width: auto;
    }
  }

  .c-btn-wrapper {
    width: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
    margin-top: 20px;

    .c-btn--sidebar {
      width:50% !important;
      padding:20px;
      border-radius:10px;
      background:#37bc9b;
      font-weight: 500;
      font-size: 17px;
    }
    .c-btn--sidebar:hover {
      background: darken(#37bc9b, 10%) !important;

    }
  }
  .c-title-list--sidebar {
    margin: 0;
    padding: 23px 0 23px 10px;
    font-size: 17px;
    background: white;
    border-radius: 10px;
  }
  input {
    border: 2px solid transparent;
  }
  input:focus {
    border: rgba(55, 65, 145, 1) 2px solid;
  }
}